import "./css/jquery-ui-1.12.1.custom.css";
import "./css/mobile.css";
import "./css/common.css";

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Login from './component/contents/Login';
import List from './component/ingredients/List';
import Write from './component/ingredients/Write';

function App() {
    return (
        <div id="wrapper">
            {/* contents */}
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Login/>}/>
                    <Route path='/list' element={<List/>}/>
                    <Route path='/write' element={<Write />} />
                </Routes>
            </BrowserRouter>
            {/* contents */}
            <footer id="footer"></footer>
        </div>
    );
}

export default App;
