import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import {db} from "../../utils/firebase";

function List() {

    const navigate = useNavigate();

    // 로그인 화면 이동
    const handleLoginPage = () => {
        navigate("/");
    }
    // 등록 화면 이동
    const handleWrite = () => {
        navigate("/write");
    }

    const [categoryItem, setCategoryItem] = useState([]); // 카테고리 데이터
    const [detailCategoryItem, setDetailCategoryItem] = useState([]); // 세부 카테고리 데이터
    const [ingredients, setIngredients] = useState([]); // 식재료
    const [ingredientCnt, setIngredientCnt] = useState(0); // 식재료 개수
    const [id, setId] = useState(sessionStorage.getItem("loggedInUser"));

    useEffect(() => {
        // 데이터 불러오기
        const fetchData = async () => {
            try {
                // 카테고리, 세부카테고리
                const querySnapshot = await getDocs(collection(db, "codes"));
                const cateData = [];
                const detailData = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // 카테고리
                    if(data.step == 1) {
                        cateData.push({ id:data.code, ...data });
                        // 세부 카테고리
                    } else if(data.step == 2) {
                        detailData.push({ id:data.code,  ...data });
                    }
                });
                setCategoryItem(cateData);
                setDetailCategoryItem(detailData);

                // 식재료
                const q2 = query(collection(db, "ingredients"), where("id", "==", id));
                const querySnapshot2 = await getDocs(q2);

                const ingredientsData = [];
                querySnapshot2.forEach((doc) => {
                    const data = doc.data();
                    ingredientsData.push({ id:data.id, ...data });
                });
                setIngredients(ingredientsData);
                setIngredientCnt(ingredientsData.length);
            } catch (error) {
                console.error("데이터를 불러오는 중 오류 발생:", error);
            }
        };
        fetchData();
    }, []);

    return(
        <>
            <header id="header">
                <button className="btn_history_back" onClick={handleLoginPage}>이전</button>
                <h2>식재료 지도</h2>
            </header>

            <main id="container">
                <div className="content">
                    <div className="tab basic">
                        <ul className="tab_menu" role="tablist">
                            {
                                categoryItem.map((item, index) => (
                                    <li key={index}><a href="" role="tab" aria-selected="true" aria-controls={`tab1_0${index + 1}`}>{item.code_name}</a></li>
                                ))
                            }
                        </ul>
                        <div id="tab1_01" className="tab_cont" role="tabpanel" aria-labelledby="tab1_01">
                            <div className="sub_tab_btns">
                                <button className="on">전체</button>
                                {
                                    detailCategoryItem.map((item, index) => (
                                        <button key={index}>{item.code_name}</button>
                                    ))
                                }
                            </div>
                            <div className="ingredients_list">
                                <div className="list_top">
                                    <p className="count"><i className="xi-paper-o"></i>전체 <b>{ingredientCnt}</b>건</p>
                                </div>
                                <ul>
                                    {
                                        ingredients.map((item, index) => (
                                            <li key={item.id}>
                                                <a href="#">
                                                    <b>{item.ingredient_name} <span className="count">{item.quantity}</span></b>
                                                    <p>{item.expiry_date}</p>
                                                </a>
                                                <button className="btn_del">삭제</button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <button className="btn_write_float" onClick={handleWrite}>등록</button>
                </div>
            </main>

            <div id="fixed_menu">
                <ul>
                    <li className="on"><a href="#"><i className="xi-map-o"></i>식재료 지도</a></li>
                    <li><a href="#"><i className="xi-list"></i>식단표</a></li>
                    <li><a href="#"><i className="xi-lightbulb-o"></i>레시피</a></li>
                    <li><a href="#"><i className="xi-cart-o"></i>장보기</a></li>
                </ul>
            </div>
        </>
    );
}

export default List;